import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'; // Ensure the CSS is imported

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setShowModal(false); // Close the modal after selection
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Button variant="outline-light" onClick={handleShow}>
        Language
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Language</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li onClick={() => changeLanguage('en')}>{t('languages.english')}</li>
            <li onClick={() => changeLanguage('el')}>{t('languages.greek')}</li>
            <li onClick={() => changeLanguage('es')}>{t('languages.spanish')}</li>
            <li onClick={() => changeLanguage('fr')}>{t('languages.french')}</li>
            <li onClick={() => changeLanguage('de')}>{t('languages.german')}</li>
            <li onClick={() => changeLanguage('it')}>{t('languages.italian')}</li>
            <li onClick={() => changeLanguage('ru')}>{t('languages.russian')}</li>
            <li onClick={() => changeLanguage('zh')}>{t('languages.chinese')}</li>
            <li onClick={() => changeLanguage('ja')}>{t('languages.japanese')}</li>
            <li onClick={() => changeLanguage('pt')}>{t('languages.portuguese')}</li>
            <li onClick={() => changeLanguage('bg')}>{t('languages.bulgarian')}</li>
            <li onClick={() => changeLanguage('da')}>{t('languages.danish')}</li>
            <li onClick={() => changeLanguage('fi')}>{t('languages.finnish')}</li>
            <li onClick={() => changeLanguage('he')}>{t('languages.hebrew')}</li>
            <li onClick={() => changeLanguage('nl')}>{t('languages.dutch')}</li>
            <li onClick={() => changeLanguage('no')}>{t('languages.norwegian')}</li>
            <li onClick={() => changeLanguage('ro')}>{t('languages.romanian')}</li>
            <li onClick={() => changeLanguage('sr')}>{t('languages.serbian')}</li>
            <li onClick={() => changeLanguage('sw')}>{t('languages.swedish')}</li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageSwitcher;
