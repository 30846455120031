import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import './Menu.css'; // Additional styling for the menu

const Menu: React.FC = () => {
  const { t } = useTranslation();

  // Function to safely translate and return a default if a key is missing
  const safeTranslate = (key: string, defaultText: string) => {
    try {
      return t(key) || defaultText;
    } catch (error) {
      console.error(`Translation error for key: ${key}`, error);
      return defaultText;
    }
  };

  return (
    <Nav className="menu-nav">
      {/* Language Switcher Positioned Before Menu Items */}
      <div className="language-switcher-container">
        <LanguageSwitcher />
      </div>

      <LinkContainer to="/">
        <Nav.Link>{safeTranslate('home', 'Home')}</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/geography/greece/municipalities">
        <Nav.Link>{safeTranslate('municipalities', 'Municipalities')}</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/geography/greece/regions">
        <Nav.Link>{safeTranslate('regions', 'Regions')}</Nav.Link>
      </LinkContainer>
      {/* Add other menu items similarly */}
    </Nav>
  );
};

export default Menu;
