// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Provides routing capabilities
import { HelmetProvider } from 'react-helmet-async'; // Provides support for managing document head
import './i18n'; // Initializes internationalization configuration
import 'bootstrap/dist/css/bootstrap.min.css'; // Includes Bootstrap CSS for consistent styling

// Render the application to the DOM with HelmetProvider for SEO support and BrowserRouter for routing
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root') // Ensures the app is mounted on the root element in index.html
);
