// CookieConsent.tsx
import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

// Extend the Window interface to include 'gtag'
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setIsVisible(false);

    // Update GA consent status to 'granted'
    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
      });
    }

    // Load AdSense script dynamically
    const script = document.createElement('script');
    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3131616609445146';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  };

  const handleManage = () => {
    window.location.href = '/privacy-policy';
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <p>
        Ο εκδότης <strong>kairos.gr</strong> ζητάει τη συγκατάθεσή σας προκειμένου να χρησιμοποιήσει
        τα προσωπικά σας δεδομένα για: εξατομικευμένη διαφήμιση και περιεχόμενο, μέτρηση διαφήμισης
        και περιεχομένου, έρευνα κοινού και ανάπτυξη υπηρεσιών. Αποθήκευση ή/και πρόσβαση στα
        δεδομένα μιας συσκευής.
      </p>
      <div className="buttons">
        <button onClick={handleAccept}>Αποδοχή</button>
        <button onClick={handleManage}>Μάθετε περισσότερα</button>
      </div>
    </div>
  );
};

export default CookieConsent;
