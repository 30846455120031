// src/components/TopBar/TopBar.tsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Alert, Button, Spinner } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './TopBar.css';

interface PlaceData {
  name: string;
  description: string | null;
  latitude: number;
  longitude: number;
  elevation: number;
  continent_slug: string;
  country_slug: string;
  region_slug: string;
  municipality_slug: string;
  municipality_name: string;
  place_slug: string;
}

const TopBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [placeData, setPlaceData] = useState<PlaceData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const storedLocation = localStorage.getItem('placeData');
    if (storedLocation) {
      setPlaceData(JSON.parse(storedLocation));
    }

    const handleLanguageChange = () => {
      setError(null);
    };

    i18n.on('languageChanged', handleLanguageChange);

    const handlePlaceDataChange = () => {
      const updatedPlaceData = localStorage.getItem('placeData');
      if (updatedPlaceData) {
        setPlaceData(JSON.parse(updatedPlaceData));
      } else {
        setPlaceData(null);
      }
    };

    window.addEventListener('placeDataChanged', handlePlaceDataChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
      window.removeEventListener('placeDataChanged', handlePlaceDataChange);
    };
  }, [i18n]);

  const handleLocationRequest = () => {
    setLoading(true);
    setError(null);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const apiUrl = `${process.env.REACT_APP_API_URL}/${i18n.language}/api/geography/nearest-place/?latitude=${latitude}&longitude=${longitude}`;

          fetch(apiUrl, {
            headers: {
              'Accept-Language': i18n.language,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              localStorage.setItem('placeData', JSON.stringify(data));
              setPlaceData(data);
              window.dispatchEvent(new Event('placeDataChanged'));
              setLoading(false);
            })
            .catch(() => {
              setError(t('topBar.error_fetching_place_data'));
              setLoading(false);
            });
        },
        (geoError) => {
          setError(`${t('topBar.error_geolocation')}: ${geoError.message}`);
          setLoading(false);
        }
      );
    } else {
      setError(t('topBar.geolocation_not_available'));
      setLoading(false);
    }
  };

  const constructPlaceUrl = (placeData: PlaceData) => {
    return `/weather/greece/${placeData.region_slug}/${placeData.municipality_slug}/${placeData.place_slug}/`;
  };

  return (
    <div className="top-bar">
      <Container fluid>
        {loading ? (
          <Alert className="loading-alert">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
            {t('topBar.loading')}...
          </Alert>
        ) : error ? (
          <Alert className="top-bar-alert">
            {error}
          </Alert>
        ) : placeData ? (
          <Alert className="top-bar-alert">
            <FaMapMarkerAlt className="me-2" />
            {t('topBar.nearest_place')}:{' '}
            <Link to={constructPlaceUrl(placeData)} className="nearest-place-link">
              {placeData.name}
            </Link>
          </Alert>
        ) : (
          <Button onClick={handleLocationRequest} className="find-nearest-btn">
            <FaMapMarkerAlt className="me-2" />
            {t('topBar.find_nearest_place')}
          </Button>
        )}
      </Container>
    </div>
  );
};

export default TopBar;
