// src/components/Footer/Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Kairos. Όλα τα Δικαιώματα Διατηρούνται.</p>
        <div className="footer-links">
          <a href="/privacy-policy" className="footer-link">Πολιτική Απορρήτου</a>
          <span className="footer-separator">|</span>
          <a href="/terms" className="footer-link">Όροι Χρήσης</a>
          <span className="footer-separator">|</span>
          <a href="/contact" className="footer-link">Επικοινωνία</a>
          <span className="footer-separator">|</span>
          <a href="/status" className="footer-link">Υγεία συστήματος</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
