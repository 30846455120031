// src/App.tsx

import React, { useEffect, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom'; // Removed BrowserRouter as Router
import { Helmet } from 'react-helmet';
import TopBar from './components/TopBar/TopBar';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CookieConsent from './components/CookieConsent';
import './App.css';

// Lazy load the pages for code splitting
const Home = React.lazy(() => import('./pages/Home/Home'));
const Municipalities = React.lazy(() => import('./pages/Municipalities/Municipalities'));
const Regions = React.lazy(() => import('./pages/Regions/Regions'));
const MunicipalityDetail = React.lazy(
  () => import('./pages/MunicipalityDetail/MunicipalityDetail')
);
const PlaceWeather = React.lazy(() => import('./pages/PlaceWeather/PlaceWeather'));
const RegionDetail = React.lazy(() => import('./pages/RegionDetail/RegionDetail'));
const NewsDetail = React.lazy(() => import('./pages/NewsDetail/NewsDetail'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound/PageNotFound'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Terms = React.lazy(() => import('./pages/TermsPage/TermsPage'));
const Contact = React.lazy(() => import('./pages/Contact/Contact'));
const StatusPage = React.lazy(() => import('./pages/StatusPage/StatusPage'));
const SkiCenters = React.lazy(() => import('./pages/SkiCenters/SkiCenters'));
const SkiCenterDetail = React.lazy(() => import('./pages/SkiCenterDetails/SkiCenterDetail'));

// Import the MapComponent
const MapComponent = React.lazy(() => import('./components/MapComponent'));

// Extend the Window interface to include 'gtag'
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

const App: React.FC = () => {
  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent === 'accepted' || consent === 'managed') {
      // Update GA consent status to 'granted'
      if (typeof window.gtag === 'function') {
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
      }

      // Load AdSense script dynamically after consent
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.async = true;
      script.setAttribute('data-ad-client', 'ca-pub-3131616609445146');
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div className="app-container">
      <Helmet>
        {/* Add meta tags for SEO */}
        <meta
          name="description"
          content="Πρόγνωση καιρού για την Ελλάδα, τους δήμους και τις περιφέρειες."
        />
        <meta name="keywords" content="Καιρός, Ελλάδα, δήμοι, περιφέρειες" />
        {/* Google Site Verification */}
        <meta name="google-site-verification" content="your-google-verification-code" />
      </Helmet>
      <div className="content-wrapper">
        <TopBar />
        <Header />
        <main className="main-content">
          <Suspense fallback={<div>Loading...</div>}>
            {/* Remove the <Router> components here */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/geography/greece/municipalities" element={<Municipalities />} />
              <Route path="/geography/greece/regions" element={<Regions />} />
              {/* Dynamic Routes */}
              <Route
                path="/weather/greece/:regionSlug/:municipalitySlug/:placeSlug"
                element={<PlaceWeather />}
              />
              <Route
                path="/geography/greece/:regionSlug/:municipalitySlug"
                element={<MunicipalityDetail />}
              />
              <Route path="/geography/greece/:regionSlug" element={<RegionDetail />} />
              <Route path="/news/:slug" element={<NewsDetail />} />

              {/* New Route for Ski Centers */}
              <Route path="/geography/greece/ski-centers" element={<SkiCenters />} />

              {/* New Route for Ski Center Detail */}
              <Route
                path="/geography/greece/ski-centers/:slug"
                element={<SkiCenterDetail />}
              />

              {/* Map Route */}
              <Route path="/map" element={<MapComponent />} />

              {/* Static Routes for AdSense Requirements */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              {/* Status page route */}
              <Route path="/status" element={<StatusPage />} />
              {/* Catch-all route for 404 page */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </main>
      </div>
      <CookieConsent /> {/* Include the CookieConsent components */}
      <Footer />
    </div>
  );
};

export default App;
