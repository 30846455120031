// src/components/Header/Header.tsx

import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from '../Logo';
import Menu from '../Menu/Menu';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <Navbar expand="lg" variant="dark" className="navbar-custom" style={{ backgroundColor: 'transparent' }}>
        <Container fluid className="d-flex align-items-center justify-content-between">
          {/* Logo positioned on the left */}
          <LinkContainer to="/">
            <Navbar.Brand className="d-flex align-items-center">
              <Logo />
            </Navbar.Brand>
          </LinkContainer>

          {/* Menu Component */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          {/* Collapsible Menu */}
          <Navbar.Collapse id="basic-navbar-nav" className="menu-container">
            <Menu />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
